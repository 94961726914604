import react from "react";
import PortImage1 from '../media/carolandschateau-00.png';
import PortImage2 from '../media/hbhouse-01.png';
import PortImage3 from '../media/embarcadero-00.png';
import PortImage4 from '../media/diamondhead-00.png';
import PortImage5 from '../media/hbhouse-02.png';
import PortImage6 from '../media/hbhouse-03.jpg';
import PortImage7 from '../media/hbhouse-04.jpg';
import PortImage8 from '../media/lotus-00.png';
//type animation
import { TypeAnimation } from 'react-type-animation';
//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants.js';

function Portfolio() {
  return (
    <section className="portfolio-section" id="portfolio">
      <div>
        <div className="portfolio-image-container">
          <img src={ PortImage1 } alt="" />
        </div>
        <div className="portfolio-image-container">
          <img src={ PortImage2 } alt="" />
        </div>
        <div className="portfolio-image-container">
          <img src={ PortImage3 } alt="" />
        </div>
        <div className="portfolio-image-container">
          <img src={ PortImage7 } alt="" />
        </div>
        <div className="portfolio-image-container">
          <img src={ PortImage8 } alt="" />
        </div>
        <div className="portfolio-image-container">
          <img src={ PortImage6 } alt="" />
        </div>
        <div className="portfolio-image-container">
          <img src={ PortImage4 } alt="" />
        </div>
        <div className="portfolio-image-container">
          <img src={ PortImage5 } alt="" />
        </div>
      </div>
    </section>
  );
}

export default Portfolio