import react from "react";
import Logo1 from '../media/logo_main.png';
//type animation
import { TypeAnimation } from 'react-type-animation';
//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants.js';

function Services() {
  return (
    <section className="services-section" id="services">
      <div className="services-container">
      
        <div className="services-list-container">
          <h4>
          Photography/Videography
          </h4>
          <p><span className='services-list-title'>High-Definition Real Estate Photography: </span><br />Capturing properties at their best with high-resolution images that highlight architectural features and ambiance.
          </p>
          <p><span className='services-list-title'>
          Cinematic Property Videography: </span><br />Creating compelling property videos with professional editing and storytelling techniques, enhancing the property's appeal.
          </p>
          <p><span className='services-list-title'>
          Commercial Photography for Businesses: </span><br />Providing businesses with striking commercial photography services for branding, marketing materials, and online presence.
          </p>
          <p><span className='services-list-title'>
          Event Videography and Photography: </span><br />Documenting events with creativity and precision, ensuring every moment is captured beautifully.
          </p>
          <p><span className='services-list-title'>
          Professional Portrait Photography: </span><br />Offering expert portrait sessions for individuals, professionals, and families, capturing personalities in stunning images.
          </p>
        </div>
        <br />
        <div className="services-list-container">
          <h4>
          Drone Services
          </h4>
          <p><span className='services-list-title'>
          Aerial Survey and Mapping: </span><br />Conducting comprehensive aerial surveys for construction sites, agricultural fields, and environmental studies, using cutting-edge drone technology.
          </p>
          <p><span className='services-list-title'>
          Drone Cinematography: </span><br />Capturing breathtaking aerial footage for films, documentaries, and promotional videos, adding a unique perspective to visual storytelling.
          </p>
          <p><span className='services-list-title'>
          Precision Agriculture Drone Services: </span><br />Assisting farmers with precision agriculture techniques, including crop monitoring, irrigation assessment, and yield prediction using drones.
          </p>
          <p><span className='services-list-title'>
          Infrastructure Inspection: </span><br />Conducting detailed inspections of infrastructure such as bridges, towers, and pipelines, providing valuable data for maintenance and repairs.
          </p>
          <p><span className='services-list-title'>
          Emergency Response and Search Operations: </span><br />Utilizing drones for rapid deployment in emergency situations, aiding search and rescue operations with aerial reconnaissance and real-time data.
          </p>
          <br />
        </div>
        <div className="services-list-container">
          <h4>
          Digital Media Editing
          </h4>
          <p><span className='services-list-title'>
          Professional Video Editing Services: </span><br />Editing raw video footage into polished, engaging content for commercials, corporate videos, and online platforms.
          </p>
          <p><span className='services-list-title'>
          High-End Photo Retouching: </span><br />Enhancing images to perfection with advanced retouching techniques, ensuring the highest quality for marketing materials and publications.
          </p>
          <p><span className='services-list-title'>
          Color Grading and Correction: </span><br />Applying expert color grading to videos and images, enhancing visual appeal and ensuring consistency across projects.
          </p>
          <p><span className='services-list-title'>
          Visual Effects and CGI Integration: </span><br />Adding visual effects and computer-generated imagery to videos, bringing creative concepts to life and enhancing overall production value.
          </p>
          <p><span className='services-list-title'>
          Interactive Virtual Tours: </span><br />Creating interactive virtual tours by editing 360-degree images, allowing viewers to explore spaces online in an immersive and interactive way.
          </p>
        </div>
      </div>
      <div>
      </div>
    </section>
  );
}

export default Services