import React from 'react';
//icons
import { CiCoffeeCup, CiHome, CiViewList, CiMail, CiCircleMore } from 'react-icons/ci';
import Logo1 from '../media/gold-logo-trans-01.png';

//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants.js';

function Navbar() {
  return (
    <div className="navbar-container">
      <motion.div
        className="links-container"
        variants={fadeIn('right', 1.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.5}}
      >

        <div>
          <a href="#home">
            <img src= { Logo1 } alt="logo" />
          </a>
        </div>

        <a href="#services">
          <h6>Services</h6>
        </a>

        <a href="#portfolio">
          <h6>Portfolio</h6>
        </a>

        <a href="#contact">
          <h6>Contact</h6>
        </a>
      </motion.div>
    </div>
  );
}

export default Navbar