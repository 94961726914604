import react from "react";
//type animation
import { TypeAnimation } from 'react-type-animation';
//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants.js';

function Footer() {
  return (
    <footer>
      <motion.div
        className="footer-container"
        variants={fadeIn('right', 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.5}}
        >
        <p>
          ©2023 SnowHawk.Media | All Rights Reserved.
        </p>
        <div>
          <div>
            <span className="SFBA">San Francisco Bay Area</span>
          </div>
          <TypeAnimation 
            sequence={[
              'Photography & Videography',
              3000,
              'Professional Drone Services',
              3000,
              'Digital Editing',
              3000,
              '& Other Digital Media Services',
              3000,
            ]}
            speed={60}
            className=""
            wrapper="span"
            repeat={Infinity}
            />
        </div>
        <div className="Benton">
          This site was <a href='https://designedbyBenton.tech' alt='https://designedbyBenton.tech' target='_blank'>designedbyBenton!</a> Contact today for free consultation!
        </div>
      </motion.div>
    </footer>
  );
}

export default Footer