import React from "react";
import Logo1 from '../media/logo_main.png';
//type animation
import { TypeAnimation } from 'react-type-animation';
//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants.js';

function Contact() {
  return (
    <section className="contact-section" id="contact">
      <div className="contact-container">
      <motion.form
        action="send_email.php"
        method="post"
        className=""
        variants={fadeIn('up', 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.5}}>
          <div className="form-upper-section">
          <p className=''>
            Contact Us
          </p>
            <div className='form-logo-container'>
              <img src={ Logo1 } alt="" />
            </div>
          </div>
          <input
          required
          name="name"
          className="input-name-email"
          type="text"
          placeholder="Your Name"
          />
          <input
          required
          name="email"
          className="input-name-email"
          type="text"
          placeholder="Your Email"
          />
           <div className="">
            <p className="form-question">What service(s) are you interested in?</p>
            <div className="form-answer">
            <p><input type="checkbox" name="service" value="Photography/Videography" /> Photography/Videography
            </p>
            <p><input type="checkbox" name="service" value="Web Development" /> Web Development
            </p>
            <p><input type="checkbox" name="service" value="Droning Services" /> Droning Services
            </p>
            <p><input type="checkbox" name="service" value="Digital Editing" /> Digital Editing
            </p>
            <p><input type="checkbox" name="service" value="UX/UI Design" /> UX/UI Design
            </p>
            <p>
              <textarea
              required
              name="message"
              className=""
              placeholder="Your message"
              ></textarea>
            </p>
            <p>
              <button type="submit" className="form-submit-btn">Send Message</button>
            </p>
            </div>
        </div>
        </motion.form>
      </div>
    </section>
  );
}

export default Contact