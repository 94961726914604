import react from "react";
import ReactPlayer  from "react-player";
import Video1 from '../media/about-video.MP4'
import HeroPic01 from '../media/embarcadero-00.png';
import HeroPic02 from '../media/me-01.JPG';
//type animation
import { TypeAnimation } from 'react-type-animation';
//motion
import { motion } from 'framer-motion';
//variants
import { fadeIn } from '../variants.js';

function Hero() {
  return (
    <section className="hero-section" id="home">
        <div className="hero-video-container">
          <ReactPlayer
            width="100%"
            height="100"
            url={ Video1 }
            autoplay={ true }
            muted={ true }
            loop={true}
            controls={false}
          />
        </div>

      <div className="slideshow-container-mobile">
        <img src={ HeroPic02 } alt='' />
      </div>
    </section>
  );
}

export default Hero